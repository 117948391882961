import React, { memo } from 'react';
import styled from 'styled-components';

const Parent = styled.svg<{ size?: string }>`
    width: ${(props) => props.size || '50px'};
    height: ${(props) => props.size || '50px'};

    position: relative;
    top: ${(props) => (props.size ? undefined : '50%')};
    left: ${(props) => (props.size ? undefined : '50%')};
    transform: ${(props) => (props.size ? undefined : 'translate(-50%, -50%)')};
`;

const Circle = styled.circle`
    transform-origin: center;
    animation-name: animation;
    animation-duration: 1.2s;
    animation-timing-function: cubic-bezier;
    animation-iteration-count: infinite;

    @keyframes animation {
        0% {
            stroke-dasharray: 1 98;
            stroke-dashoffset: -105;
        }
        50% {
            stroke-dasharray: 80 10;
            stroke-dashoffset: -160;
        }
        100% {
            stroke-dasharray: 1 98;
            stroke-dashoffset: -300;
        }
    }
`;

interface SpinnerProps {
    size?: string;
}

const Spinner = ({ size }: SpinnerProps) => (
    <Parent viewBox='0 0 100 100' size={size}>
        <defs>
            <filter id='shadow'>
                <feDropShadow
                    dx='0'
                    dy='0'
                    stdDeviation='1.5'
                    floodColor='rgb(27,214,165)'
                />
            </filter>
        </defs>
        <Circle
            id='spinner'
            style={{
                fill: 'transparent',
                stroke: 'rgb(27,214,165)',
                strokeWidth: '10px',
                strokeLinecap: 'round',
                filter: 'url(#shadow)',
            }}
            cx='50'
            cy='50'
            r='45'
        />
    </Parent>
);

export default memo(Spinner);
