/* -------------------------------------------------------------------------------------

    | Moar React Router |

    - TLD
    localhost:3001              - (development)
    ENV.studio.moar.community   - (dev / uat)
    my.moar.studio              - (prod / live)

    - Path              - Location

    /                   - Homepage / Show Manager
    /$unknownPath       - Redirect to /

    /edit               - Create New Show
    /edit/$showID       - Edit Show with id: $showID

    /stream             - Pre-Stream Selection
    /stream/$showID     - Stream Show with id: $showID

    /user-agreement     - User Agreement via Policies
    /privacy-policy     - Privacy Policy via Policies
    /copyright          - Copyright via Policies
    /terms-of-service   - Terms of Service via Polices

    LAZY-LOADING        - Spinner
    ERROR-CATCHING      - Error Catching Page

    ------------------------------------------------------------------------------------------------------------------

    LAZY-LOADING

    This code uses React Router 6 to lazy load routes.
    The `lazy()` function takes a function as an argument,
    which returns a promise that resolves to the route component.
    The route component can then be rendered inside a `Suspense` component,
    which will display a fallback while the route is loading.

    The `Suspense` component is used to display a fallback while the routes are loading.
    The fallback can be any component,
    but it is typically a loading spinner or a message that tells
    the user that the route is loading.

    ERROR-CATCHING

    The `errorElement` property is used to specify a fallback component
    that will be displayed when an error occurs while loading a route.

    TODO:
    - Error Catching - Show Correct Errors - https://reactrouter.com/en/main/route/error-element

    TODO:
    - Error Catching - better styled / designed error page

    TODO:
    - Unload Saving for Editng - save changes onBeforeUpload - https://reactrouter.com/en/main/hooks/use-before-unload

---------------------------------------------------------------------------------------------------------------------*/

import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from 'react-router-dom';

import Spinner from './components/Spinner';

import type { ReactNode } from 'react';

const App = lazy(() => import('./App'));
const AdminPage = lazy(() => import('./pages/AdminPage'));
const EditPage = lazy(() => import('./pages/EditPage'));
const ErrorPage = lazy(() => import('./pages/ErrorPage'));
const HomePage = lazy(() => import('./pages/HomePage'));
const PoliciesPage = lazy(() => import('./pages/PoliciesPage'));
const PreStreamPage = lazy(() => import('./pages/PreStreamPage'));
const StreamPage = lazy(() => import('./pages/StreamPage'));

const container = document.getElementById('root');
const root = createRoot(container!);

const errorElement: ReactNode = (
    <Suspense>
        <ErrorPage />
    </Suspense>
);

const router = createBrowserRouter([
    {
        path: '/',
        element: (
            <Suspense>
                <App />
            </Suspense>
        ),
        errorElement,
        children: [
            {
                path: '/',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <HomePage />
                    </Suspense>
                ),
                errorElement,
            },
            {
                path: '/pre-stream',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <PreStreamPage />
                    </Suspense>
                ),
                errorElement,
            },
            {
                path: '/edit',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <EditPage />
                    </Suspense>
                ),
                errorElement,
            },
            {
                path: '/edit/:showId',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <EditPage />
                    </Suspense>
                ),
                errorElement,
            },
            {
                path: '/stream',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <StreamPage />
                    </Suspense>
                ),
                errorElement,
            },
            {
                path: '/stream/:showId',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <StreamPage />
                    </Suspense>
                ),
                errorElement,
            },
            {
                path: '/user-agreement',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <PoliciesPage />
                    </Suspense>
                ),
                errorElement,
            },
            {
                path: '/privacy-policy',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <PoliciesPage />
                    </Suspense>
                ),
                errorElement,
            },
            {
                path: '/copyright',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <PoliciesPage />
                    </Suspense>
                ),
                errorElement,
            },
            {
                path: '/terms-of-service',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <PoliciesPage />
                    </Suspense>
                ),
                errorElement,
            },
            {
                path: '/admin',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <AdminPage />
                    </Suspense>
                ),
                errorElement,
            },
            {
                path: '*',
                element: <Navigate to='/' replace />,
            },
        ],
    },
]);

root.render(<RouterProvider router={router} />);
